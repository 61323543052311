'use strict';

const base = require('brand_core/components/headerKeyboardNav');
const keyboardAccessibility = require('./keyboardAccessibility');

/**
 * keyboard events for secondary anchors inside open flyout menu
 */
base.secondaryNav = function () {
    keyboardAccessibility('.sitenav-secondary__li',
        {
            13: function () { // enter
                let $this = $(this);
                base.navigateLink(this);
                const $firstMenuItem = $this.find('li:visible').first();
                if ($firstMenuItem.length) { // if there is a tertiary navigation
                    $firstMenuItem.trigger('focus');
                }
                return true;
            },
            37: function () { // left
                $(this).closest('.sitenav-secondary__ul').prev('.sitenav-secondary__ul').find('li:visible')
                    .first()
                    .trigger('focus');
            },
            38: function (parentLi) { // up
                let $prevTertiaryitems = $(this).prev('li:visible').find('li:visible');

                if ($prevTertiaryitems.length) {
                    $prevTertiaryitems.last().trigger('focus');
                } else if ($(this).prevAll('li:visible').length) {
                    base.previousMenuItem(this);
                } else {
                    base.previousMenuLevel(parentLi);
                }
                return true;
            },
            39: function () { // right
                $(this).closest('.sitenav-secondary__ul').next('.sitenav-secondary__ul').find('li:visible')
                    .first()
                    .trigger('focus');
            },
            40: function () { // down
                let $nextTertiaryitems = $(this).children('ul.sitenav-tertiary__ul');

                if ($nextTertiaryitems.length) {
                    $nextTertiaryitems.children('li:visible').first().trigger('focus');
                } else if ($(this).nextAll('li:visible').length) {
                    base.nextMenuItem(this);
                }
                return true;
            }
        },
        function () {
            return $(this).closest('.sitenav-primary__li');
        }
    );
};

/**
 * keyboard events for tertiary anchors inside open flyout menu
 */
base.tertiaryNav = function () {
    keyboardAccessibility('.sitenav-tertiary__li',
        {
            13: function () { // enter
                base.navigateLink(this);
                return true;
            },
            38: function () { // up
                if ($(this).prevAll('li:visible').length) {
                    base.previousMenuItem(this);
                } else {
                    $(this).closest('.sitenav-secondary__li').trigger('focus');
                }
                return true;
            },
            40: function () { // down
                if ($(this).nextAll('li:visible').length) {
                    base.nextMenuItem(this);
                } else {
                    $(this).closest('.sitenav-secondary__li').next('.sitenav-secondary__li').trigger('focus');
                }
                return true;
            }
        },
        function () {
            return $(this).closest('.sitenav-secondary__li');
        }
    );
};

module.exports = base;
